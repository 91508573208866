import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
// import * as sections from "../components/sections"
import Fallback from "../components/fallback"
import { Container, Heading, Space, Subhead } from "../components/ui"

interface ContactProps {
  data: {
    datoCmsContact: {
      aboutNode: {
        childMarkdownRemark: {
          html: string
        }
      }
      commissionsNode: {
        childMarkdownRemark: {
          html: string
        }
      }
      contactNode: {
        childMarkdownRemark: {
          html: string
        }
      }
      exhibitions: {
        year: number
        exhibitions: {
          gallery: string
          month: string
          title: string
        }[]
      }[]
    }
  }
}

export default function Contact(props: ContactProps) {
  const { datoCmsContact } = props.data

  return (
    <Layout title="Contact">
      <Container>
        <Heading as="h1">
          Contact
        </Heading>
        <Subhead as="h2">
          About
        </Subhead>
        <div
          dangerouslySetInnerHTML={{
            __html: datoCmsContact.aboutNode.childMarkdownRemark.html,
          }}
        />
        <Space size={4} />
        <Subhead as="h2">
          Commissions
        </Subhead>
        <div
          dangerouslySetInnerHTML={{
            __html: datoCmsContact.commissionsNode.childMarkdownRemark.html,
          }}
        />
        <Space size={4} />
        <Subhead as="h2">
          Email
        </Subhead>
        <div
          dangerouslySetInnerHTML={{
            __html: datoCmsContact.contactNode.childMarkdownRemark.html,
          }}
        />
        <Space size={4} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  {
    datoCmsContact {
      aboutNode {
        childMarkdownRemark {
          html
        }
      }
      commissionsNode {
        childMarkdownRemark {
          html
        }
      }
      contactNode {
        childMarkdownRemark {
          html
        }
      }
      exhibitions {
        year
        exhibitions {
          gallery
          month
          title
        }
      }
    }
  }
`
